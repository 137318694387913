.noscroll {
  overflow: hidden;
}

.lightbox {
  display: none;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
}

.flexfs {
  z-index: 10000;
  position: fixed;
  top: 1%;
  left: 1%;
  width: 96.5%;
  opacity: 1;
}

.depth0 {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.depth1,
input[type="submit"],
.button {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.depth2,
input[type="submit"]:hover,
.button:hover {
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16), -1px 2px 6px rgba(0, 0, 0, 0.16);
}

.depth3,
.head,
.profil,
.projekt-item-visual {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
}

.depth4,
.btn_top,
.button-round,
.projekt-item:hover .projekt-item-visual {
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.15), -2px 8px 12px rgba(0, 0, 0, 0.1);
}

.depth5,
.teaser-items li,
.teaser-title {
  box-shadow: 0px 15px 24px 6px rgba(0, 0, 0, 0.2);
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

@media (min-width: 719px) {
  .flex-container a:active,
  .flexslider a:active,
  .flex-container a:focus,
  .flexslider a:focus {
    outline: none;
  }
  .slides,
  .flex-control-nav,
  .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  @font-face {
    font-weight: normal;
    font-style: normal;
  }
  .flexslider {
    margin: 0;
    padding: 0;
  }
  .flexslider .slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
  }
  .flexslider .slides img {
    width: 100%;
    display: block;
  }
  .flex-pauseplay span {
    text-transform: capitalize;
  }
  .slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  html[xmlns] .slides {
    display: block;
  }
  * html .slides {
    height: 1%;
  }
  .no-js .slides > li:first-child {
    display: block;
  }
  .flexslider {
    margin: 0 0 60px;
    position: relative;
    border-radius: 4px;
    zoom: 1;
  }
  .flex-viewport {
    max-height: 2000px;
    transition: all 1s ease;
  }
  .loading .flex-viewport {
    max-height: 300px;
  }
  .flexslider .slides {
    zoom: 1;
  }
  .carousel li {
    margin-right: 5px;
  }
  .flex-direction-nav {
    *height: 0;
    z-index: 99999;
  }
  .flex-direction-nav a {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 0 0;
    position: absolute;
    z-index: 10;
    bottom: 120px;
    overflow: hidden;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  }
  .flex-direction-nav .flex-prev {
    left: -1em;
    background-image: url(/images/icon_left.png);
    background-position: 13px 11px;
  }
  .flex-direction-nav .flex-next {
    right: -0.85em;
    text-align: right;
    background-image: url(/images/icon_right.png);
    background-position: 18px 11px;
  }
  .flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
  }
  .flex-direction-nav a:before {
    font-size: 40px;
    display: inline-block;
  }
  .flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
  }
  .flex-pauseplay a:before {
    font-family: "flexslider-icon";
    font-size: 20px;
    display: inline-block;
    content: "";
  }
  .flex-pauseplay a:hover {
    opacity: 1;
  }
  .flex-pauseplay a.flex-play:before {
    content: "";
  }
  .flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
  }
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  .flex-control-paging li a {
    width: 16px;
    height: 16px;
    display: block;
    background: #ddd;
    background: #fff;
    cursor: pointer;
    text-indent: -9999px;
    border-radius: 50%;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16),
      -1px 2px 6px rgba(0, 0, 0, 0.16);
    border: none;
  }
  .flex-control-paging li a:hover {
    background: #e65100;
    background: rgba(230, 81, 0, 0.75);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
  }
  .flexslider .flex-next,
  .flexslider .flex-prev {
    opacity: 1;
    border-radius: 50%;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
    background-color: #f9a634;
    background-repeat: no-repeat;
    opacity: 0.75;
    border: none;
  }
  .flexslider .flex-next:hover,
  .flexslider .flex-prev:hover {
    opacity: 1;
    box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.15),
      -2px 8px 12px rgba(0, 0, 0, 0.1);
  }
  .flex-control-paging li a.flex-active {
    background: #e65100;
    background: #e65100;
    cursor: default;
  }
  .flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
  }
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
  }
  .flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: 0.7;
    cursor: pointer;
  }
  .flex-control-thumbs img:hover {
    opacity: 1;
  }
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
  }
}

html {
  font-size: 16px;
}

body {
  margin: 0 0 0 0;
  background: #fffaf2;
  height: 100%;
  color: #616161;
  overrflow-y: scroll;
}

body,
textarea,
input {
  font-family: "Noto Sans", sans-serif;
}

p,
table,
blockquote,
textarea,
input {
  line-height: 1.65em;
}

h1,
h2,
h3,
h4 {
  font-family: "Signika", sans-serif;
  color: #17765f;
}

h1 {
  text-align: left;
  font-size: 1.75em;
  font-weight: 500;
  line-height: 1.1em;
  margin: 0 0 0.5em 0;
}

h2 {
  text-align: left;

  font-size: 1.15em;
  line-height: 1.3;
  font-weight: 400;
  margin: 2em 0 0.5em 0;
  color: #616161;
}

a {
  text-decoration: underline;
  text-decoration: none;
  border-bottom: 3px solid #17765f;
  border-bottom: 3px solid rgba(23, 118, 95, 0);
  color: #17765f;
  transition: 0.25s;
}

a:hover {
  border-bottom: 3px solid #17765f;
  border-bottom: 3px solid rgba(23, 118, 95, 0.5);
}

hr {
  clear: both;
  width: 100%;
  margin: 2em 0 2em 0;
  height: 1px;
  background: #eee;
  border: none;
}

p {
  width: auto;
  display: block;
  max-width: 60em;
  margin: 0 0 1em 0;
}

li {
  text-align: left;
}

form {
  margin: 1em 0 0 0;
}

input {
  margin: 0 0 0 0;
  padding: 0.2em;
  width: 40%;
}

input,
textarea,
input[type="submit"] {
  border: 1px solid #ddd;
  padding: 0.5em;
  border-radius: 2px;
}

textarea {
  width: 95%;
  max-width: 45em;
  height: 10em;
}

input:focus,
textarea:focus {
  outline-color: #17765f;
}

input[type="text"],
textarea {
  background: #fafafa;
  background: rgba(255, 255, 255, 0.5);
  transition: 0.5s;
  padding: 0.35em 0.75em 0.35em 0.75em;
}

input[type="text"]:focus,
textarea:focus {
  background: #fff;
  background: rgba(255, 255, 255, 0.9);
}

input[type="submit"],
.button {
  display: inline-block;
  width: auto;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 0 0 0;
  padding: 0.75em 1em 0.75em 1em;
  cursor: pointer;
  line-height: 1em;
  font-weight: 400;
  margin: 0 0 0;
  background: #f9a634;
  text-decoration: none;
  border-radius: 0.25em;
  transition: 0.25s;
  border: none;
}

input[type="submit"]:hover,
.button:hover {
  background: #e28b00;
  text-decoration: none;
  border: none;
}

label {
  display: block;
  width: auto;
  clear: both;
  margin: 1em 0 0.25em 0;
  font-size: 1.25em;
  font-weight: 400;
}

::-webkit-input-placeholder {
  color: #414141;
}

:-moz-placeholder {
  color: #414141;
}

::-moz-placeholder {
  color: #414141;
}

:-ms-input-placeholder {
  color: #414141;
}

.head {
  z-index: 9999;
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: auto;
}

.head-wrapper {
  position: relative;
  margin: auto auto auto auto;
  padding: 0 0 0 0;
  max-width: 960px;
  height: 145px;
}

.head-pullmenu {
  position: absolute;
  bottom: -25px;
  left: 193px;
  width: 40px;
  height: 25px;
  background: url(/images/icon_pullmenu.png) no-repeat 0 0;
  cursor: pointer;
}

.head-content {
  position: relative;
  width: auto;
  height: 100%;
  margin: auto auto auto auto;
  padding: 0 0 0 0;
  max-width: 960px;
}

.head-logo {
  position: static;
  bottom: 0;
  left: 0;
  display: block;
  width: 338px;
  height: 130px;
  overflow: hidden;
  margin: 1em 0 1em 2em;
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-image: url(/images/logo.png);
  background-size: 338px 130px;
  text-decoration: none;
  border-bottom: none;
}

.head-logo:hover {
  text-decoration: none;
  border-bottom: none;
}

.head-deco {
  display: block;
  margin: 0 auto 0 auto;
  padding: 0 0 0 0;
  width: 560px;
  height: 80px;
  overflow: auto;
  position: absolute;
  bottom: 1px;
  right: 2em;
  background: url(/images/head_deco.png) no-repeat 0 0;

  opacity: 0.15;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.nav-container {
  z-index: 9999;
  position: absolute;
  bottom: 6.75em;
  right: 7em;
  width: auto;
}

.nav,
.menu {
  display: inline-block;
  margin: 0 auto 0 auto;
  padding: 0 0 0 0;
  width: auto;
  height: auto;
  overflow: auto;
}

.nav li,
.menu li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: left;
  display: block;
}

.nav li a,
.menu li a {
  display: block;
  width: auto;
  margin: 0 0.25em 0 0.25em;
  padding: 0.25em 0.25em 0.25em 0.25em;
  line-height: 1em;
  background: none;
  text-decoration: none;
  font-size: 1.05em;
  font-weight: 400;
  color: #616161;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  border-bottom: 3px solid rgba(23, 118, 95, 0);
}

.nav li a:hover,
.menu li a:hover {
  color: #17765f;
  color: rgba(23, 118, 95, 0.8);
  border-bottom: 3px solid #17765f;
  border-bottom: 3px solid rgba(23, 118, 95, 0.35);
}

.nav li a.nav-topic-active,
.menu li.current-menu-item a {
  color: #17765f;
  border-bottom: 3px solid #17765f;
  font-weight: 400;
}

.container {
  width: auto;
  height: auto;
  overflow: auto;
  margin: 0 0 0 0;
  padding: 1.25em 2em 1.25em 2em;
  border-bottom: 3px solid #dfd3ab;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.container--top {
  position: relative;
  padding-top: 11em;
}

.container--bottom {
  border-bottom: none;
  overflow: auto;
}

.container-content {
  z-index: 10000;
  display: block;
  width: auto;
  max-width: 960px;
  margin: 2em auto 2em auto;
}

.container-footer {
  position: relative;
  overflow: hidden;
}

.notice {
  z-index: 11111;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 1em 0 0 0;
  text-align: center;
}

.notice-text {
  display: inline-block;
  width: auto;
  margin: auto;
  padding: 1em 2em 1em 2em;
  border-radius: 1em;
  background: #555;
  background: rgba(50, 50, 50, 0.75);
  color: #fff;
  font-size: 0.9em;
  text-align: center;
}

.btn_top {
  z-index: 9999;
  display: none;
  position: fixed;
  bottom: 3%;
  right: 3%;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 0.25em;
  background-image: url(/images/icon_top.png);
  background-repeat: no-repeat;
  background-position: 10px 15px;
  background-color: #f9a634;
  border-radius: 50%;
  opacity: 0.85;
  border: none;
}

.btn_top:hover {
  opacity: 1;
  border: none;
}

.btn_top span {
  display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.btn-menu {
  z-index: 15000;
  width: 1.375em;
  height: 1.25em;
  background-image: url(/images/sprites.png);
  background-repeat: no-repeat;
  background-position: 0 -2em;
}

.container-bg1 {
  background-color: #fff4e5;
}

.container-bg2 {
  background-color: #fae8b9;
}

.container-bg5 {
  background-color: #666;
}

.container-bg11 {
  background-color: #444;
}

.content-body {
  font-size: 1.1em;
}

.content--center {
  text-align: centlefter;
  margin-left: auto;
  margin-right: auto;
}

.content--center h1 {
  text-align: left;
}

.content--center p {
}

.footer-container {
  color: #aaa;
  text-align: center;
}

.footer-container h2 {
  margin: 0 0 -0.25em 0;
  color: #bbb;
  font-size: 2em;
  text-align: center;
}

.footer-container p {
  margin: 1em 0 0.25em 0;
  max-width: 100%;
}

.footer-contactname {
  width: 14em;
}

.footer-container a {
  color: #fff;
  border-color: #aaa;
  border-color: rgba(255, 255, 255, 0);
}

.footer-container a:hover {
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}

.footer-nav {
  margin: 0.25em 0 -1em 0;
  padding: 0 1em 0 1em;
  overflow: hidden;
}

.footer-nav li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
  color: #fff;
}

.footer-nav li a {
  display: inline-block;
  color: #fff;
  border-color: #888;
  border-color: rgba(255, 255, 255, 0);
}

.footer-nav li a:hover {
  border-color: #aaa;
  border-color: rgba(255, 255, 255, 0.5);
}

.footer-nav li.footer-left {
  text-align: left;
  float: left;
}

.footer-nav li.footer-right {
  text-align: right;
  float: right;
}

.teaser-items {
  margin: 150px auto 0 auto;
  padding: 0;
  width: auto;
  display: inline-block;
  text-align: center;
}

.teaser-items li {
  list-style-type: none;
  font-family: "Signika", sans-serif;
  display: block;
  float: left;
  width: 8em;
  height: 7em;
  text-align: center;
  color: #fff;
  font-size: 1.25em;
  line-height: 1.35em;
  font-weight: 300;
  margin: auto 1.5em auto 1.5em;
  padding: 3.5em 1.5em 0.5em 1.5em;
  border-radius: 50%;
  opacity: 0;
  margin-top: -210px;
  transition: 1s ease-in-out;
  border: 1px solid transparent;
  background-color: #17765f;
  background-color: rgba(23, 118, 95, 0.85);
}

.teaser-items li.teaser-items--show {
  transform: translateY(0);
  opacity: 1;
}

.teaser-title {
  font-family: "Signika", sans-serif;
  display: inline-block;
  width: 7em;
  height: 6.5em;
  text-align: center;
  color: #fff;
  font-size: 2.25em;
  font-weight: 300;
  margin: 100px auto 0 auto;
  padding: 2em 1em 0.25em 1em;
  background-color: #17765f;
  background: rgba(23, 118, 95, 0.85);
  border-radius: 50%;
}

.teaser-title--hidden {
  opacity: 0;
}

.teaser--top {
  margin-top: 120px;
}

.teaser {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 500px;
  padding: 0 0 0 0;
  border-bottom: 3px solid #dfd3ab;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  background-attachment: fixed;
  position: relative;
  position: relative;
  z-index: 100;
}

.teaser-2 {
  background-position: right top;
  background-image: url(/images/teaser_stauden1.jpg);
}

.teaser-3 {
  background-image: url(/images/teaser_freiraumplanung-1.png);
}

.teaser-4 {
  background-image: url(/images/teaser_landschaftsplan-2.jpg);
  background-position: center top;
}

.teaser-2--mobile {
  background-image: url(/images//images_mobile/teaser_stauden1.jpg);
}

.teaser-3--mobile {
  background-image: url(/images//images_mobile/teaser_freiraumplanung-1.png);
}

.teaser-4--mobile {
  background-image: url(/images//images_mobile/teaser_landschaftsplan-2.jpg);
}

.container-profil {
  float: left;
  display: inline-block;
  width: 42%;
  text-align: center;
  margin: 1em 3% 1em 3%;
}

.profil {
  background-repeat: no-repeat;
  background-size: cover;
  width: 240px;
  height: 240px;
  max-width: 240px;
  max-height: 240px;
  border-radius: 50%;
  margin: 0 auto 1em auto;
  border: 6px solid #fffaf2;
}

.container-profil h2 {
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  margin: 0 0 0.5em 0;
  line-height: 1em;
}

.profil-title-job {
  margin: 0 0 0 0;
  display: block;
  font-weight: 300;
  font-size: 0.5em;
}

.profil-matthiesen {
  background-image: url(/images/profil_matthiesen.jpg);
}

.profil-schlegel {
  background-image: url(/images/profil_schlegel.jpg);
}

.align-left {
  text-align: left;
}

.content--left {
  max-width: 39em;
  margin-left: auto;
  margin-right: auto;
}

.content-body ul {
  padding: 0;
  margin: 1.5em auto 1.5em auto;
  max-width: 38em;
}

.content-body ul li {
  display: inline-block;
  width: auto;
  background: #ffe082;
  color: #444;
  padding: 0.4em 1.25em 0.4em 1.25em;
  margin: 6px 4px 8px 4px;
  font-size: 0.8em;
  list-style-type: none;
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.75);
  border-radius: 16px;
  text-align: center;
}

.paper {
  background-image: url(/images/head_bg.jpg);
}

.divider {
  width: 40%;
  text-align: center;
  margin: 1.5em auto 1.2em auto;
  height: 1px;
  background-color: #ccc;
  background-color: rgba(30, 67, 124, 0.2);
}

.divider-symbol {
  background-image: url(/images/icon_divider.png);
  background-repeat: no-repeat;
  background-position: 4px 0;
  width: 20px;
  height: 12px;
  margin: 0 auto 0 auto;
  position: relative;
  top: -6px;
}

.button-round {
  width: 48px;
  height: 48px;
  display: block;
  padding: 0;
  margin: auto;
  border-radius: 0.25em;
  background-image: url(/images/icon_bottom.png);
  background-repeat: no-repeat;
  background-position: 10px 18px;
  background-color: #f9a634;
  border-radius: 50%;
  opacity: 0.85;
  border: none;
  font-size: 0;
}

.button-round:hover {
  opacity: 1;
  border: none;
}

.button-center {
  display: block;
  float: none;
  clear: both;
  max-width: 100%;
  text-align: center;
  padding: 1em 0 0 0;
  margin: 0 0 0 0;
}

.button-center .button {
  margin: auto;
  _font-size: 1.1em;
}

.footer-cta {
  z-index: 0;
  position: absolute;
  left: 48%;
  top: 20%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 2s ease-in-out;
}

.footer-cta--play {
  opacity: 1;
  transform: scale(18);
  background: transparent;
}

.contact-form {
  clear: both;
  margin: 1em auto 0 auto;
  padding: 0;
  width: auto;
  max-width: 45em;
  text-align: left;
}

.contact-form-result {
  padding: 4%;
  margin: 0 0 1em 0;
  background: #f0f0f0;
  background: rgba(255, 255, 255, 0.5);
}

.contact-form-result-title {
  margin: 0 0 0.5em 0;
}

.contact-confirmation {
  clear: both;
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 100px auto 0 auto;
  overflow: auto;
}

.contact-confirmation h1 {
  text-align: center;
  margin: 0;
}

.services-list {
  text-align: center;
}

.clear {
  clear: both;
}

@media (max-width: 640px) and (min-width: 0px) {
  textarea {
    width: 93%;
  }
  input {
    width: 93%;
  }
}

.depth0 {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.depth1,
input[type="submit"],
.button {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
}

.depth2,
input[type="submit"]:hover,
.button:hover {
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16), -1px 2px 6px rgba(0, 0, 0, 0.16);
}

.depth3,
.head,
.profil,
.projekt-item-visual {
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
}

.depth4,
.btn_top,
.button-round,
.projekt-item:hover .projekt-item-visual {
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.15), -2px 8px 12px rgba(0, 0, 0, 0.1);
}

.depth5,
.teaser-items li,
.teaser-title {
  box-shadow: 0px 15px 24px 6px rgba(0, 0, 0, 0.2);
}

.flexslider-container {
  max-width: 1280px;
  overflow: hidden;
  margin: auto;
  padding: 0 0 3em 0;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.flexslider {
  width: 98%;
  background: #fff;
  margin: 0 auto 0.5em auto;
  padding: 0;
  z-index: 0;
  border: none;
  border-radius: 0;
  height: auto;
  border: 8px solid #fff;
  -o-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25);
}

.flexslider .slides {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}

.flexslider .slides li {
  list-style-type: none;
  position: relative;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}

.slide_info {
  height: 7.5em;
  margin: 0 0 0 0;
  text-align: left;
  padding: 1em 1em 0 1em;
  border-top: 0.5em solid #fff;
}

.slide_info--bg {
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  _background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(
    transparent,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.5)
  );
  _border-top: 4px solid rgba(255, 255, 255, 0.25);
  width: 94.5%;
  height: auto;
  padding: 2em;
  color: #fff;
  font-size: 1.1em;
  font-weight: 400;
  _text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.75);
}

.slide_info p {
  max-width: 66em;
  margin: 0 auto 0 auto;
}

.slide_info p:first-letter {
  font-size: 1.25em;
  font-weight: 400;
  color: #17765f;
}

.slide_left,
.slide_right {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 85%;
  cursor: pointer;
}

.slide_right {
  left: auto;
  right: 0;
}

.flexslider .slides li img {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  max-width: 100%;
  border-radius: 0;
}

.flex-direction-nav a {
  bottom: 10em;
}

.flex-control-nav {
  z-index: 9;
  position: absolute;
  bottom: 10em;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
}

.flex-direction-nav a,
.flex-control-nav {
  bottom: 10em;
}

.projekt-item {
  clear: both;
  float: none;
  text-align: left;
  margin: auto 0 3em auto;
  padding: 0 0 1em 0;
  overflow: auto;
  height: auto;
  max-width: 48em;
  max-width: 51em;
}

.projekt-item-text {
  overflow: hidden;
  margin-top: 0.5em;
  min-height: 220px;
}

.projekt-item h2 {
  font-weight: 400;
  margin: 0 0 0.5em 0;
}

.projekt-item p {
  max-width: 100%;
}

.projekt-item .button {
  font-size: 0.9em;
}

.projekt-item-visual {
  float: left;
  margin: 0.25em 3em 0 0;
  margin: 0.25em 3em 1.25em 1.25em;
  width: 33%;
  max-width: 240px;
  border-radius: 50%;
  padding: 0;
  border: 6px solid #fffaf2;
}

.projekt-item-visual {
  transition: 0.35s;
}

.projekt-item:hover .projekt-item-visual {
  transform: scale(1.05);
}

@media (max-width: 719px) {
  .flexContainer {
    position: static;
    float: none;
    clear: both;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .flexslider {
    background: none;
    border: none;
    position: static;
    float: none;
    clear: both;
    width: auto;
    height: auto;
    margin: 0 auto 0 auto;
    padding: 0;
    display: block;
    box-shadow: none;
  }
  .slides {
    display: block;
    position: static;
    float: none;
    width: 100%;
    height: auto;
    list-style-type: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    height: 100%;
  }
  .slides li {
    display: block;
    position: static;
    float: none;
    height: auto;
    list-style-type: none;
    margin: 0 0 2em 0 !important;
    padding: 0 0 0 0;
    overflow: auto;
    width: auto !important;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.25);
    border: 8px solid #fff;
  }
  .slides img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
  .flexslider .slides > li img {
    display: block;
  }
  .slide_info {
    height: auto;
    padding: 1em 1em 1em 1em;
  }
}

@media (max-width: 960px) {
  .head {
    position: absolute;
    top: -52px;
    left: 0;
    margin: 0;
    padding: 0;
  }
  .head-logo {
    position: absolute;
    top: -30px;
    left: 2em;
    margin: 0;
    background-image: url(/images/logo-2x.png);
  }
  .head-pullmenu {
    display: none;
  }
  .head-deco {
    bottom: 0;
    opacity: 0.1;
  }
  .nav-container {
    bottom: 0.35em;
    right: 2em;
    width: 100%;
    margin: 0;
    text-align: right;
  }
  .nav {
    display: inline-block;
    width: auto;
    margin: auto;
  }
  .nav li a {
    font-size: 0.9em;
  }
  .container--top {
    padding-top: 100px;
  }
  .teaser--top {
    margin-top: 60px;
  }
}

@media (max-width: 719px) {
  .btn_top {
    right: 46%;
  }
  h1 {
    font-size: 2em;
  }
  .container-profil {
    margin: 1em 0 3em 0;
    float: none;
    display: block;
    width: 100%;
  }
}

@media (max-width: 479px) {
  h1 {
    font-size: 1.75em;
  }
  .head-logo {
    width: 200px;
  }
  .nav-container {
    right: 0;
    text-align: center;
  }
  .projekt-item {
    margin: auto 0 3em auto;
    max-width: 100%;
  }
  .projekt-item img {
    float: none;
    margin: 0em 0 1em 0;
    width: 100%;
    max-width: 100%;
  }
  input[type="submit"] {
    margin-top: 1em;
  }
  .footer-container {
    font-size: 0.9em;
  }
  .footer-nav,
  .footer-nav li,
  .footer-nav li.footer-left,
  .footer-nav li.footer-right {
    display: block;
    float: none;
    text-align: center;
  }
  .footer-nav li {
    margin: 1em 0 0 0;
  }
}

.flexslider {
  width: 98%;
}

@media (min-aspect-ratio: 1 / 1) and (min-width: 720px) {
  .flexslider {
    width: 90%;
  }
}

@media (min-aspect-ratio: 20 / 10) and (min-width: 720px) {
  .flexslider {
    width: 80%;
  }
}

@media (min-aspect-ratio: 25 / 10) and (min-width: 720px) {
  .flexslider {
    width: 70%;
  }
}

@media (max-width: 879px) {
  .teaser-items li {
    width: 7em;
    height: 6.25em;
    font-size: 0.8em;
    line-height: 1.35em;
    padding: 2.75em 1.5em 0.5em 1.5em;
    margin-top: -150px;
    opacity: 0;
  }
}

@media (max-width: 719px) {
  .teaser-title--hidden {
    opacity: 1;
  }
  .teaser-items li {
    width: 7em;
    height: 6.25em;
    font-size: 0.8em;
    line-height: 1.35em;
    margin: auto 1.5em auto 1.5em;
    padding: 2.75em 1.5em 0.5em 1.5em;
  }
  .teaser {
    height: 400px;
  }
  .teaser-title {
    width: 8em;
    height: 7em;
    font-size: 1.5em;
    margin: 80px auto 0 auto;
    padding: 2.75em 1em 0.25em 1em;
  }
}

@media (max-width: 519px) {
  .projekt-item {
    max-width: 100%;
    overflow: hidden;
  }
  .projekt-item img {
    border-radius: 0;
    float: none;
    width: 95%;
    max-width: 100%;
    margin: 0 0 1em 0;
  }
  .teaser-items li {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
  .teaser-title--hidden {
    opacity: 1;
  }
  .teaser-items li:nth-child(1) {
    top: 6em;
    left: 28%;
  }
  .teaser-items li:nth-child(2) {
    top: 18em;
    left: 5%;
  }
  .teaser-items li:nth-child(3) {
    top: 18em;
    left: 62%;
  }
}

@media (max-width: 439px) {
  .teaser-items li:nth-child(1) {
    top: 6em;
    left: 25%;
  }
  .teaser-items li:nth-child(2) {
    top: 18em;
    left: 3%;
  }
  .teaser-items li:nth-child(3) {
    top: 18em;
    left: 50%;
  }
}

@media (max-width: 440px) {
  .nav li a,
  .menu li a {
    font-size: 0.8em;
  }
}

@font-face{font-display:swap;font-family:"Signika";font-style:normal;font-weight:300;src:url("../fonts/signika-v25-latin-300.woff2") format("woff2")}

@font-face{font-display:swap;font-family:"Signika";font-style:normal;font-weight:400;src:url("../fonts/signika-v25-latin-regular.woff2") format("woff2")}

@font-face{font-display:swap;font-family:"Signika";font-style:normal;font-weight:500;src:url("../fonts/signika-v25-latin-500.woff2") format("woff2")}

p{font-weight:300 !important;font-family:"Signika" !important}